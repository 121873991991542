<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <LoginPage v-if="!logged" @logged="handleLogged"/>
  <main v-else class="full-page">
    <div class="vertical-splitter">
      <Transition name="slide-fade">
        <SideBar @updateFilters="updateFilters" v-show="toggleSideBar"/>
      </Transition>
      <section class="main-display" :style="{ width: toggleSideBar ? '85%' : '100%'}">
        <TopBar @toggleSideBar="toggleSideBar = !toggleSideBar" :sidebar="toggleSideBar"/>
        <router-view class="scrollable" :filters="filters" @unlog="logged = false"/>
      </section>
    </div>
  </main>
</template>

<script>
import LoginPage from '@/components/Login.vue'
import TopBar from '@/sideElements/TopBar.vue'
import SideBar from '@/sideElements/SideBar.vue';
import store from './store';
export default {
  name: 'AppView',
  components: {
    LoginPage,
    TopBar,
    SideBar
  },
  data () {
    return {
      logged: false,
      filters: {},
      toggleSideBar: true,
      dataStore: store
    }
  },
  methods: {
    updateFilters(payload) {
      this.filters = payload
    },
    handleLogged() {
      this.logged = true
    }
  },
  created () {
    if(localStorage.getItem('userData')) {
      let json = JSON.parse(localStorage.getItem('userData'))
      if(json.LoginUser && json.apiUser && json.apiPassword && json.idUser) {
        this.dataStore.api.apiUser = json.apiUser
        this.dataStore.api.apiPassword = json.apiPassword
      }
    }
  }
}
</script>

<style>
.color-invert {
  filter: invert(1)
}
.color-gray {
  filter: opacity(0.5)
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.vertical-splitter {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}
.main-display {
  height: 100vh;
  overflow-y: hidden;
}
.hidden {
  visibility: hidden;
}
.full-page {
  max-height: 100vh;
  overflow-y: hidden;
}
.scrollable {
  height: 93vh;
  overflow-y: auto;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100%);
}
</style>
